<template>
  <div>
    <AppTable
      :rows="holdUsers.value"
      :columns="columns"
      :is-loading="requestInProgress"
      :pagination="{
        limit: holdUsers.limit,
        count: holdUsers.count,
        page: holdUsers.page,
      }"
      @change-limit="changePageLimit"
      @change-page="changePage"
    >
      <template #prependFilters>
        <b-row>

          <b-col md="3" xl="3" class="mb-1">
            <b-form-group>
              <label for="datepicker-placeholder">Level name</label>

              <v-select
                v-model="levelIds"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="levels"
                :reduce="option => option.id"
              />
            </b-form-group>
          </b-col>

          <b-col md="3" xl="3" class="mb-1">
            <b-form-group>
              <label class="mr-1">Search</label>
              <b-form-input v-model="searchUser" placeholder="TG username / Email" type="text" />
            </b-form-group>
          </b-col>
          <b-col md="3" xl="3" class="mb-1">
            <b-form-group>
              <label for="datepicker-placeholder">Sort colum</label>

              <v-select
                v-model="sortColumn"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="sortColumnList"
                :reduce="option => option.key"
              />
            </b-form-group>
          </b-col>

          <b-col md="2" xl="2" class="mb-1">
            <b-form-group>
              <label class="mr-1">Sort order</label>
              <b-form-select
                v-model="sortOrder"
                :options="sortOrderList"
              />
            </b-form-group>
          </b-col>

          <b-col md="1" xl="1" class="mb-1 mt-auto pb-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="requestInProgress"
              class="mt-auto"
              type="button"
              variant="primary"
              @click="search"
            >
              <feather-icon icon="SearchIcon" size="18" />
            </b-button>
          </b-col>
        </b-row>
      </template>

      <template #default="{ column, row, formattedRow} = {}">
        <TableRow :row="row" :column="column" :formatted-row="formattedRow" />

        <span v-if="column.field === 'telegramUsername'">
          <a
            :href="`https://t.me/${row.telegramUsername}`"
            target="_blank"
          >
            {{ row.telegramUsername }}
          </a>
        </span>

        <span v-else-if="column.field === 'level'" class="flex-center-align">
          <span class="ml-1">{{ row.level.name }}</span>
        </span>

        <span v-else-if="column.field === 'balance'" class="flex-center-align">
          {{ `${$decimal(row.balance).toDecimalPlaces(3)}` }}
        </span>

        <span v-else-if="column.field === 'totalReferralFarmingReward'" class="flex-center-align">
          {{ `${$decimal(row.totalReferralFarmingReward).toDecimalPlaces(3)}` }}
        </span>

        <span v-else-if="column.field === 'levelReferrals'">
          <div v-for="(item,index) in row.levelReferrals" :key="index" class="">
            <span>level {{ index }} - {{ item }}</span>
          </div>
        </span>

        <span v-else class="flex-center-align">
          {{ formattedRow[column.field] }}
        </span>
      </template>
    </AppTable>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import store from '@/store/index'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'
import transactions from '@/mixins/transactions'
import columns from './config/tableConfig'
import TableRow from '@/components/TableRow.vue'
import AppTable from '@/components/AppTable.vue'
import AssetFilter from '@/components/containers/AssetFilter.vue'
import vSelect from 'vue-select'

export default {
  name: 'HoldUsers',
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    AppTable,
    TableRow,
    AssetFilter,
    BFormCheckbox,
    BFormSelect,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [waitRequest, validationError, transactions],

  data() {
    return {
      columns,
      currenPage: 1,
      selectPerPage: 30,
      rowsPerPageOptions: [10, 30, 50],

      searchUser: '',
      levelIds: null,
      sortColumn: '',
      sortOrder: 'asc',

      sortColumnList: [
        { "id": 1, "name": "Activations", "key": "activations" },
        { "id": 2, "name": "Balance", "key": "balance" },
        { "id": 3, "name": "Ref bonus amount", "key": "totalReferralFarmingReward" }
      ],
      sortOrderList: [
        { value: 'asc', text: 'Asc' },
        { value: 'desc', text: 'Desc' },
      ],
    }
  },

  computed: {
    ...mapGetters({
      holdUsers: 'hold/holdUsers',
      levels: 'hold/levels',
    }),
    direction() {
      return store.state.appConfig.isRTL
    },
  },

  mounted() {
    this.initState()
  },

  methods: {
    ...mapActions({
      fetchListWalletsCeFi: 'wallets/fetchListWalletsCeFi',
      fetchListHoldUsers: 'hold/fetchListHoldUsers',
      fetchHoldLevels: 'hold/fetchHoldLevels',
    }),

    createQueryConfig(config) {
      return {
        page: this.holdUsers.page,
        limit: this.holdUsers.limit,

        levelIds: this.levelIds ? [this.levelIds] : undefined,
        search: this.searchUser || undefined,
        sortColumn: this.sortColumn || undefined,
        sortOrder: this.sortOrder || undefined,

        ...config,
      }
    },

    initState() {
      const limit = this.selectPerPage
      const query = this.createQueryConfig({ limit })
      this.fetchUsers(query)
      this.fetchHoldLevels()
    },

    search() {
      const query = this.createQueryConfig({
        search: this.searchUser,
        page: 1,
      })
      return this.fetchUsers(query)
    },

    changePageLimit(pageLimit) {
      const query = this.createQueryConfig({ limit: pageLimit })
      return this.fetchUsers(query)
    },
    changePage(pageNumber) {
      const query = this.createQueryConfig({ page: pageNumber })
      return this.fetchUsers(query)
    },

    fetchUsers(query) {
      return this.waitRequest(() => this.fetchListHoldUsers(query).catch(this.checkErrors))
    },

    handleKolChange(user) {
      if (this.requestInProgress) return

      this.waitRequest(() => this.toggleKOL({ id: user.id }))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-good-table.scss';

.coin-img {
  width: 30px;
  border-radius: 50%;
}
</style>