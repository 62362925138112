export default [
  {
    label: 'TG username',
    field: 'telegramUsername',
  },
  {
    label: 'Email',
    field: 'email',
  },
  {
    label: 'Level name',
    field: 'level',
  },
  {
    label: 'Number of invited users',
    field: 'levelReferrals',
  },
  {
    label: 'HP balance',
    field: 'balance',
  },
  {
    label: 'Ref bonus amount',
    field: 'totalReferralFarmingReward',
  },
  // {
  //   label: 'Is KOL',
  //   field: 'isKOL',
  // },
]
